import React from 'react';

import styled from 'styled-components';

import {Card, CardHead, CardTitle, CardExtra, CardContent} from '../helpers/Card';

import Notices from './Notices';

export default class Home extends React.Component {
  render() {

    let {
      currentUser,
    } = this.props;

    return(
      <Wrapper>
        <Card
        >
          <CardHead>
            <CardTitle>
              Notice Board
            </CardTitle>
          </CardHead>
          <Notices currentUser={currentUser} />
        </Card>
        <Card
        >
          <CardHead>
            <CardTitle>
              Your Tasks
            </CardTitle>
          </CardHead>
          MOCK
        </Card>
        <Card
        >
          <CardHead>
            <CardTitle>
              Your Tickets
            </CardTitle>
          </CardHead>
          MOCK
        </Card>
      </Wrapper>
    )
  }
}


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const Wrapper = styled.div`
  margin: 35px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-column-gap: 35px;
`
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

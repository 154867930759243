import firebase from 'firebase';

export function convertUnixTimestamp(timestamp) {
  let date = new Date(timestamp); // Multiplied to convert it to milliseconds
  let day = ('0' + date.getDate()).substr(-2); // 0 added for single-digit padding
  let month = ('0' + date.getMonth()).substr(-2);
  let hours = ('0' + date.getHours()).substr(-2);
  let minutes = ('0' + date.getMinutes()).substr(-2);

  return hours + ':' + minutes + ' ' + day + '/' + month;
}

/* All load*() functions require to be bound to the component from which they are
    being called.
    e.g.
    (in the component's constructor):
      this.loadRecords = loadRecords.bind(this);
      this.loadNewRecordsPage = loadNewRecordsPage.bind(this);
      this.loadSearchedRecords = loadSearchedRecords.bind(this);
      this.loadRecordsReversed = loadRecordsReversed.bind(this);
      this.loadNewRecordsPageReversed = loadNewRecordsPageReversed.bind(this);
*/

export async function loadRecords(source, destination, orderBy='uid', limit=25, initialBuffer=[]) {
  let buffer = initialBuffer.slice();

  await firebase.database().ref(source)
  .orderByChild(orderBy)
  .limitToFirst(limit)
  .once('value').then((snapshot) => {
    snapshot.forEach((snap) => {
      const uid = snap.key;
      if (snap.val().uid) {
        buffer.push(snap.val())
      }
    });
  })

  let stateBuffer = {};
  stateBuffer[destination] = buffer;

  return this.setState(stateBuffer);
}

export async function loadRecordsReversed(source, destination, orderBy='uid', limit=25, initialBuffer=[]) {
  let buffer = initialBuffer.slice();

  await firebase.database().ref(source)
  .orderByChild(orderBy)
  .limitToLast(limit)
  .once('value').then((snapshot) => {
    snapshot.forEach((snap) => {
      const uid = snap.key;
      if (snap.val().uid) {
        buffer.unshift(snap.val())
      }
    });
  });

  let stateBuffer = {};
  stateBuffer[destination] = buffer;

  return this.setState(stateBuffer);
}

export async function loadNewRecordsPage(source, destination, orderBy='uid', startAtValue='\u0000', startAtUid=null, limit=25, initialBuffer=[]) {
  let buffer = initialBuffer.slice();

  await firebase.database().ref(source)
  .orderByChild(orderBy)
  .startAt(startAtValue, startAtUid)
  .endAt('\uf8ff')
  .limitToFirst(limit)
  .once('value').then((snapshot) => {
    snapshot.forEach((snap) => {
      const uid = snap.key;
      if (snap.val().uid) {
        if (buffer[buffer.length-1].uid != uid) {
          buffer.push(snap.val())
        }
      }
    });
  });

  let stateBuffer = {};
  stateBuffer[destination] = buffer;

  this.setState(stateBuffer);
}

export async function loadNewRecordsPageReversed(source, destination, orderBy='uid', startAtValue='\u0000', startAtUid=null, limit=25, initialBuffer=[]) {
  let buffer = initialBuffer.slice();
  let sortingBuffer = [];

  await firebase.database().ref(source)
  .orderByChild(orderBy)
  .endAt(startAtValue, startAtUid)
  .limitToLast(limit)
  .once('value').then((snapshot) => {
    snapshot.forEach((snap) => {
      const uid = snap.key;
      if (snap.val().uid) {
        if (buffer[buffer.length-1].uid != uid) {
          sortingBuffer.unshift(snap.val())
        }
      }
    });
  });

  sortingBuffer.forEach((disc, index) => {
    buffer.push(disc);
  });

  let stateBuffer = {};
  stateBuffer[destination] = buffer;

  this.setState(stateBuffer);
}

export async function loadSearchedRecords(source, destination, orderBy='uid', startAtValue='\u0000', limit=25, initialBuffer=[]) {

  let buffer = initialBuffer.slice();

  await firebase.database().ref(source)
  .orderByChild(orderBy)
  .startAt(startAtValue)
  .endAt(startAtValue+'\uf8ff')
  .limitToFirst(limit)
  .once('value').then((snapshot) => {
    snapshot.forEach((snap) => {
      if (snap.val().uid) {
        buffer.push(snap.val())
      }
    })
  })

  let stateBuffer = {};
  stateBuffer[destination] = buffer;

  this.setState(stateBuffer);
}

/*~~~~~~~~~~~~~~~ Underscore.js ~~~~~~~~~~~~~~~~~~~*/

// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
export function throttle(func, wait, options) {
  var timeout, context, args, result;
  var previous = 0;
  if (!options) options = {};

  var later = function() {
    previous = options.leading === false ? 0 : now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };

  var throttled = function() {
    var now = function() {
      return new Date().getTime();
    };
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };

  throttled.cancel = function() {
    clearTimeout(timeout);
    previous = 0;
    timeout = context = args = null;
  };

  return throttled;
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  var timeout, result;

  var later = function(context, args) {
    timeout = null;
    if (args) result = func.apply(context, args);
  };

  var debounced = restArguments(function(args) {
    if (timeout) clearTimeout(timeout);
    if (immediate) {
      var callNow = !timeout;
      timeout = setTimeout(later, wait);
      if (callNow) result = func.apply(this, args);
    } else {
      timeout = delay(later, wait, this, args);
    }

    return result;
  });

  debounced.cancel = function() {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
};

// A (possibly faster) way to get the current timestamp as an integer.
  let now = function() {
    return new Date().getTime();
  };

  // Some functions take a variable number of arguments, or a few expected
  // arguments at the beginning and then a variable number of values to operate
  // on. This helper accumulates all remaining arguments past the function’s
  // argument length (or an explicit `startIndex`), into an array that becomes
  // the last argument. Similar to ES6’s "rest parameter".
  var restArguments = function(func, startIndex) {
    startIndex = startIndex == null ? func.length - 1 : +startIndex;
    return function() {
      var length = Math.max(arguments.length - startIndex, 0),
      rest = Array(length),
      index = 0;
      for (; index < length; index++) {
        rest[index] = arguments[index + startIndex];
      }
      switch (startIndex) {
        case 0: return func.call(this, rest);
        case 1: return func.call(this, arguments[0], rest);
        case 2: return func.call(this, arguments[0], arguments[1], rest);
      }
      var args = Array(startIndex + 1);
      for (index = 0; index < startIndex; index++) {
        args[index] = arguments[index];
      }
      args[startIndex] = rest;
      return func.apply(this, args);
    };
  };

  // Delays a function for the given number of milliseconds, and then calls
  // it with the arguments supplied.
  let delay = restArguments(function(func, wait, args) {
    return setTimeout(function() {
      return func.apply(null, args);
    }, wait);
  });



  // module.exports = {
  //   throttle: throttle,
  //   debounce: debounce,
  //   convertUnixTimestamp: convertUnixTimestamp,
  //   // loadNextUsersPage: loadNextUsersPage
  // }

import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';

import firebase from 'firebase';
import styled from 'styled-components';

import {Card, CardHead, CardTitle, CardExtra, CardContent} from '../helpers/Card';
import {convertUnixTimestamp} from '../helpers/Utils';


export default class SingleUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: props.match.params.uid,
      user: {}
    }
  }

  componentDidMount() {
    const {uid} = this.state;

    firebase.database().ref('users/' + uid)
    .on('value', function(snapshot) {
      const user = snapshot.val();
      this.setState({
        user: user
      });
    }.bind(this));
  }

  handlePicError(event) {
    // TODO: Make local, this is just a test!
    let placeholderAvatar = 'http://icity.brussels/wp-content/uploads/2017/08/placeholder-face-big.png';
    event.target.src = placeholderAvatar;
  }

  handleSendNotification() {

  }

  render() {
    const {
      firstName,
      uid,
      email,
      avatar,
      online,
      lastOnline
    } = this.state.user;

    return (
      <Wrapper>
        <Card>
          <CardHead>
            <CardTitle>
              User Info
            </CardTitle>
            <CardExtra>
              <Link to={"/notification/text/"+uid}>
                <i
                  className="fa fa-bell"
                  onClick={this.handleSendNotification}
                />
              </Link>
            </CardExtra>
          </CardHead>
          <CardContent>
            <UserInfo>
              <UserPic>
                <img
                  src={avatar}
                  alt=""
                />
              </UserPic>
              <EditableComponent
                value={firstName}
                getUpdateData={getUpdateName}
                uid={uid}
              />
              <EditableComponent
                value={email}
                getUpdateData={getUpdateEmail}
                uid={uid}
              />
              <div>
                online:&nbsp;
                {
                  online
                  ? <i className="fa fa-check-circle green"></i>
                  : <i className="fa fa-times-circle red"></i>
                }
              </div>
              <div>
                last online: {convertUnixTimestamp(lastOnline)}
              </div>
            </UserInfo>
          </CardContent>
        </Card>
        <Card>
          <CardHead>
            <CardTitle>
              Activity Log
            </CardTitle>
          </CardHead>
          <CardContent>
            MOCK
          </CardContent>
        </Card>
        <Card className="full-width">
          <CardHead>
            <CardTitle>
              Tickets
            </CardTitle>
          </CardHead>
          <CardContent>
            MOCK
          </CardContent>
        </Card>
      </Wrapper>
    )
  }
}

function getUpdateName(value) {
  return {
    firstName: value
  }
}

function getUpdateEmail(value) {
  return {
    email: value
  }
}

// TODO: HOC?
class EditableComponent extends React.Component {
  // TODO: Comment
  constructor(props) {
    super(props);
    this.state = {
      isBeingEdited: false,
      value: ``,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({
      isBeingEdited: !this.state.isBeingEdited,
    })
  }

  handleChange(event) {
   this.setState({value: event.target.value});
 }

  handleSubmit(event) {
    event.preventDefault();
    let updateData = this.props.getUpdateData(this.state.value);
    firebase.database().ref('/users/')
    .child(this.props.uid)
    .update(updateData);
    this.setState({
      isBeingEdited: false
    })
  }

  render() {
    const {
      isBeingEdited
    } = this.state;

    const {
      value
    } = this.props;

    if (isBeingEdited) {
      return (
        <EditForm onSubmit={this.handleSubmit}>
          <EditElement
            autoFocus
            required
            placeholder={value}
            onChange={this.handleChange}
          />
          <input type="submit" value="" />
          <button onClick={this.handleClick}>
            <i className="fa fa-times"></i>
          </button>
        </EditForm>
      )
    }else {
      return (
        <NonEditWrapper>
          <NonEditElement>{value}</NonEditElement>
          <button onClick={this.handleClick}>
            <i className="fa fa-edit"></i>
          </button>
        </NonEditWrapper>
      )
    }
  }
}


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const NonEditWrapper = styled.div`
  ${NonEditWrapper} button {
    background: none;
    border: none;
    cursor: pointer
  }
`

const EditForm = styled.form`

  ${EditForm} > button {
    background: none;
    border: none;
    cursor: pointer;
  }

  ${EditForm} > input[type=submit] {
    display: none;
  }
`

const EditElement = styled.input`
  border: none;
  box-shadow: none;
  outline: none;
  border-bottom: 1px solid black;
  transition: border-bottom .3s linear;

  ${EditElement}:focus {
    border-bottom: 1px solid teal;
  }
`

const NonEditElement = styled.span`

`

const Wrapper = styled.div`
  width: 100%; height: calc( 100vh - 75px );
  padding: 35px;
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 35px;

  .full-width {
    grid-column: 1/-1;
  }
`

const UserInfo = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${UserInfo} > * {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  & .green {
    color: limegreen;
  }

  & .red {
    color: #ED4337;
  }
`

const UserPic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;

  img {
    width: 60px; height: 60px;
    margin: auto;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/tefter-be7a6.appspot.com/o/app%2FanonymousLogo.jpg?alt=media&token=0e6f0b2c-ac8c-4907-b1a9-3c3fb69b4288);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    object-fit: cover;
  }
`
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
// #0c2733

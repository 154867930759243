import React from 'react';
import {Link} from 'react-router-dom';

import firebase from 'firebase';
import styled from 'styled-components';

import {Card, CardHead, CardTitle, CardExtra, CardContent} from '../helpers/Card';
import {convertUnixTimestamp} from '../helpers/Utils';

export default class SingleDisc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: props.match.params.uid,
      disc: {},
      orderIndex: 0,
      discComm: [],
      editorsChoice: false
    }

    this.handleToggleEditorsChoice = this.handleToggleEditorsChoice.bind(this);
    this.handleOrderIndexChange = this.handleOrderIndexChange.bind(this);
  }

  async componentDidMount() {
    const {uid} = this.state;

    await firebase.database().ref('discussions/' + uid)
    .once('value')
    .then((discussion) => {
      this.setState({
        disc: discussion.val(),
        orderIndex: discussion.val().orderIndex
      });
    });

    firebase.database().ref('discussionComments/' + uid)
    .once('value')
    .then((snapshot) => {
      const discComm = snapshot.val();
      const discCommArr = [];

      snapshot.forEach( function (snap) {
        discCommArr.push(snap.val());
      });

      this.setState({
        discComm: discCommArr
      });
    });

    await firebase.database().ref('editorsChoice')
    .once('value')
    .then((discussions) => {
      discussions.forEach((disc) => {
        if (disc.key == uid) {
          this.setState({
            editorsChoice: true
          })
        }
      })
    });
  }

  handleToggleEditorsChoice() {
    let uid = this.state.disc.uid;
    let editorsChoice = this.state.editorsChoice;

    let updatedChoice = {};
    updatedChoice[uid] = !editorsChoice;

    firebase.database()
    .ref('/editorsChoice/')
    .update(updatedChoice);

    this.setState({
      editorsChoice: !editorsChoice
    })

  }

  handleOrderIndexChange(event) {
    const target = event.target;
    const newOrderIndex = parseInt(target.value);
    const {uid} = this.state;

    this.setState({
      orderIndex: newOrderIndex
    });

    firebase.database()
    .ref('/discussions/')
    .child(uid)
    .update(
      {orderIndex: newOrderIndex}
    )
  }

  handleSendNotification() {

  }

  render() {
    const {
      uid,
      disc,
      discComm,
      editorsChoice,
      orderIndex
    } = this.state;

    return(
      <Wrapper>
        <Card
        >
          <CardHead>
            <CardTitle>
              {uid}
            </CardTitle>
            <CardExtra>
              <Link to={"/notification/text/"+uid}>
                <i
                  className="fa fa-bell"
                  onClick={this.handleSendNotification}
                />
              </Link>
            </CardExtra>
          </CardHead>
          <CardContent>
            <Disc>
              <DiscParams>
                <OrderIndex>
                <input
                  type="number"
                  value={orderIndex}
                  onChange={this.handleOrderIndexChange}
                  step="1"
                  min="0"
                  name="orderIndex"
                />
                </OrderIndex>
                <EditorsChoice>
                  <i
                    onClick={this.handleToggleEditorsChoice}
                    className={
                      editorsChoice
                      ? "fa fa-star selected"
                      : "fa fa-star"
                    }
                  />
                </EditorsChoice>
              </DiscParams>
              <DiscAuthor>
                <AuthorPic>
                  <img src={disc.authorAvatarLink} alt="" />
                </AuthorPic>
                <AuthorName>
                  <Link to={'/users/' + disc.authorUid}>
                    {disc.authorName}
                  </Link>
                </AuthorName>
                <DiscDate>
                  {convertUnixTimestamp(disc.creationDate)}
                </DiscDate>
              </DiscAuthor>
              <DiscMessage>
                {disc.message}
              </DiscMessage>
              {disc.questionImageUrl
              ? <DiscImage>
                  <img src={disc.questionImageUrl} alt="" />
                </DiscImage>
              : null}
            </Disc>
          </CardContent>
        </Card>
        <Card
        >
          <CardHead>
            <CardTitle>
              DiscussionComments
            </CardTitle>
          </CardHead>
          <CardContent>
            <Solutions>
              {discComm.map((comment, i) =>
                <SolutionWrapper key={comment.uid+'comm'}>
                  <SolutionAuthor>
                    <AuthorPic>
                      <img src={comment.authorAvatarLink} alt="" />
                    </AuthorPic>
                    <AuthorName>
                      <Link to={'/users/' + comment.authorUid}>
                        {comment.authorName}
                      </Link>
                    </AuthorName>
                  </SolutionAuthor>
                  <SolutionMessage>
                    {comment.message}
                  </SolutionMessage>
                </SolutionWrapper>
              )}
            </Solutions>
          </CardContent>
        </Card>
      </Wrapper>
    )
  }
}


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const Wrapper = styled.div`
  margin: 35px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 35px;
`

const Disc = styled.div`
  width: 50vw;
  height: calc( 100vh - 75px - 70px - 4em );
  margin: auto;
  padding: 35px 0;
  padding-left: 2em;
  display: grid;
  grid-template-columns: 40vw;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  overflow-y: scroll;

  a {
    text-decoration: none;
    color: teal;
  }

  a:hover {
    text-decoration: underline;
  }
`

const DiscParams = styled.div`
  grid-column: 2/3;
  display: flex;
`

const EditorsChoice = styled.div`
  i {
    color: lightgrey;
    transition: color .15s linear;
  }

  i.selected {
    color: teal;
  }

  i:hover {
    color: teal;
    cursor: pointer;
  }
`

const OrderIndex = styled.div`
  input {
    width: 8ch;
  }
`

const DiscAuthor = styled.div`
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr 1fr;
`

const DiscDate = styled.div`
  grid-row: 2/3;
  grid-column: 2/3;
  font-size: .8em;
  color: grey;
  padding-left: 10px;
  display: flex;
  align-items: center;
`

const AuthorPic = styled.div`
  grid-row: 1/3;
  grid-column: 1/2;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  height: 60px;

  img {
    width: 60px; height: 60px;
    margin: auto;
    border-radius: 100%;
  }
`

const AuthorName = styled.div`
  grid-row: 1/2;
  grid-column: 2/3;
  display: inline;
  line-height: 2rem;
  padding-left: 10px;
  font-size: 1.5em;
`

const DiscMessage = styled.div`
  display: flex;
  justify-content: center;
  overflow-wrap: break-word;
  grid-column: 1/2;
  grid-row: 2/4;
`

const DiscImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 2/3;
  grid-row: 2/4;

  img {
    max-width: 20vw;
    max-height: 30vh;
    transition: opacity .1s linear;
  }

  img:hover {
    opacity: .8;
    cursor: pointer;
  }
`

const Solutions = styled.div`
  padding: 35px;
  position: relative;
  width: 100%; height: 100%;
  max-height: calc( 100vh - 75px - 70px - 4em );
  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 35px;
`

const SolutionWrapper = styled.div`
 display: grid;
 grid-template-columns: 20vw;
`

const SolutionAuthor = styled.div`
  ${AuthorPic} img {
    width: 40px; height: 40px;
  }

  ${AuthorName} {
    font-size: 1.2em;
  }
`

const SolutionMessage = styled.div`
  overflow-wrap: break-word;
`

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

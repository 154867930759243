import React from 'react';

import styled from 'styled-components';

import {loadRecords, loadNewRecordsPage, loadSearchedRecords, convertUnixTimestamp} from '../../helpers/Utils';

export default class Disc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scopeUsersParams: this.props.currParams
    };

    this.setUsersPerPage = this.setUsersPerPage.bind(this);
    this.setOrderBy = this.setOrderBy.bind(this);
  }

  async setUsersPerPage(event) {
    let params = this.state.scopeUsersParams;
    params.usersPerLoad = parseInt(event.target.value);
    await this.setState({
      scopeUsersParams: params
    });

    this.props.sendParams(this.state.scopeUsersParams)
  }

  async setOrderBy(event) {
    let params = this.state.scopeUsersParams;
    params.orderBy = event.target.value;
    await this.setState({
      scopeUsersParams: params
    });

    this.props.sendParams(this.state.scopeUsersParams)
  }

  render() {
    return(
      <Wrapper>
        <span>Users per page:</span>
        <select onChange={this.setUsersPerPage}>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </select>
        <span>Order By:</span>
        <select onChange={this.setOrderBy}>
          <option value="firstName">firstName</option>
          <option value="uid">uid</option>
          <option value="creationDate">creationDate</option>
        </select>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  max-width: 50vw;
  max-height: 50vh;
  background: white;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 35px;
  color: #aaa;
  font-family: 'Source Sans Pro', sans-serif;

  span {
    width: 55%;
    color: black;
    font-size: .8em;
    margin-right: 5%;
    float: left;
  }

  select {
    width: 20%;
  }
`

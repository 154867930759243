import React from 'react';

import firebase from 'firebase';
import styled from 'styled-components';

import {
  loadRecords,
  loadRecordsReversed,
  loadNewRecordsPage,
  loadNewRecordsPageReversed,
  loadSearchedRecords,
  convertUnixTimestamp,
  debounce } from '../../helpers/Utils';
import {Card, CardHead, CardTitle, CardExtra, CardContent} from '../../helpers/Card';

export default class ScopeDisc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discussions: [],
      participants: [],
      recipients: this.props.recipients,
      selectedInScopeDisc: [],
      modifiedRecipients: []
    };
    this.loadRecords = loadRecords.bind(this);
    this.loadRecordsReversed = loadRecordsReversed.bind(this);
    this.loadNewRecordsPage = loadNewRecordsPage.bind(this);
    this.loadNewRecordsPageReversed = loadNewRecordsPageReversed.bind(this);
    this.loadSearchedRecords = loadSearchedRecords.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleScrollLoad = this.handleScrollLoad.bind(this);
    this.handleSelectDiscussion = this.handleSelectDiscussion.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.isImmutable = this.isImmutable.bind(this);
    this.handleExitScopeDisc = this.handleExitScopeDisc.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  async componentDidMount() {
    this.loadRecordsReversed('/discussions', 'discussions', 'orderIndex');

    let recipientsCopy = this.state.recipients.slice();

    await this.setState({
      modifiedRecipients: recipientsCopy
    })
  }

  handleScrollLoad(){
    const container = document.getElementById('scope-disc-list');
    const viewportHeight = container.clientHeight;
    const pageHeight = container.scrollHeight;
    const scrollPos = container.scrollTop;

    if (scrollPos == pageHeight - viewportHeight) {
      const {
        discussions
      } = this.state;

      let startAtValue = discussions[discussions.length-1].orderIndex;
      let startAtUid = discussions[discussions.length-1].uid;
      this.loadNewRecordsPageReversed('/discussions',
                              'discussions',
                              'orderIndex',
                              startAtValue,
                              startAtUid,
                              10,
                              discussions);
    }
  }

  async handleSelectDiscussion(event) {
    event.preventDefault();
    const target = event.currentTarget;
    const discUid = target.id;
    await this.setState({
      participants: []
    })
    const participants = this.state.participants;
    const participantsBuffer = [];

    //Adding the OP
    await firebase.database().ref('/discussions')
    .child(discUid)
    .once('value')
    .then(async (discussion) => {
      const disc = discussion.val();
      if (disc.anonymous) {
        await firebase.database().ref('/users')
        .child(disc.authorUid)
        .once('value')
        .then((userSnapshot) => {
          let user = userSnapshot.val();
          disc.authorName = user.firstName + '(anon)';
        });
      }
      let buffer = {
        uid: disc.authorUid,
        firstName: disc.authorName
      };
      participantsBuffer.push(buffer);
    });

    //adding the rest of participants
    await firebase.database().ref('/discussionComments')
    .child(discUid)
    .once('value')
    .then(async (comments) => {
      let comms = comments.val();
      for(const uid in comms) { 
        let comm = comms[uid];
        if (comm.anonymous) { 
          //de-anon anonymous users in the form of "First Name (anon)"
          await firebase.database().ref('/users')
          .child(comm.authorUid)
          .once('value')
          .then((userSnapshot) => {
            let user = userSnapshot.val();
            comm.authorName = user.firstName + '(anon)';
          });
        }
        let buffer = {
          uid: comm.authorUid,
          firstName: comm.authorName
        }
        if (!participantsBuffer.some(participant => participant.uid == buffer.uid)) {
          participantsBuffer.push(buffer);
        }
      }

      this.setState({
        participants: participantsBuffer
      });
      
    });
  }

  // Handles mode change and user addition to the local recipients list,
  // upon selecting a user.
  async handleSelect(event) {
    const checkbox = event.target;
    let checked = checkbox.checked;

    let {
      recipients,
      participants,
      selectedInScopeDisc,
      modifiedRecipients
    } = this.state;

    const recipientsBuffer = modifiedRecipients.slice();

    if (!recipientsBuffer || recipientsBuffer.length == 0) {
      recipientsBuffer.push({
        name: null,
        uid: 'empty'
      })
    }

    let recipientsMode = recipientsBuffer[0].uid;

    if (checkbox.id == 'select-all-disc') {
      if (checked) {
        if (recipientsMode == 'empty') {
          recipientsBuffer[0].uid = 'select';
          participants.forEach((participant) => {
            recipientsBuffer.push(participant);
            selectedInScopeDisc.push(participant);
          });
        } else if (recipientsMode == 'all-except') {
          recipientsBuffer.forEach((recipient, rindex) => {
            participants.forEach((participant, pindex) => {
              if (recipient.uid == participant.uid) {
                recipientsBuffer.splice(rindex, 1);
                selectedInScopeDisc.push(participant);
              }
            })
          });

          // If we removed every user from recipients array with "all-except"
          // mode, then we are broadcasting to every user hence the change of
          // mode to "all"
          if (recipientsBuffer.length == 1) {
            recipientsBuffer[0].uid = 'all';
          }
        } else if (recipientsMode == 'select') {
          let skip = false;
          participants.forEach((participant, pindex) => {
            recipientsBuffer.forEach((recipient, rindex) => {
              if (recipient.uid == participant.uid) {
                skip = true;
              }
            });
            if (!skip) {
              recipientsBuffer.push(participant)
              selectedInScopeDisc.push(participant);
            };
            skip = false;
          })
        }
      } else {
        if (recipientsMode == 'all' || recipientsMode == 'select' || recipientsMode == 'all-except') {
          participants.forEach((participant, pindex) => {
            selectedInScopeDisc.forEach((user, sindex) => {
              if (user.uid == participant.uid) {
                recipientsBuffer.forEach((recipient, rindex) => {
                  if (recipient.uid == user.uid) {
                    recipientsBuffer.splice(rindex, 1);
                    selectedInScopeDisc.splice(sindex, 1);
                  }
                });
              }
            });
          })
        }
      }
    } else {
      let buffer = {
        uid: '',
        firstName: ''
      };

      await firebase.database().ref('/users')
      .child(checkbox.id)
      .once('value')
      .then((userSnap) => {
        let user = userSnap.val();
        buffer.uid = user.uid;
        buffer.firstName = user.firstName;
      });

      if (checked) { // add to selection

        if (recipientsMode == 'empty' || recipientsMode == 'select') {
          recipientsBuffer[0].uid = 'select';
          recipientsBuffer.push(buffer);
          selectedInScopeDisc.push(buffer);
        } else if (recipientsMode == 'all-except') {
          recipientsBuffer.forEach((recipient, rindex) => {
            if (recipient.uid == buffer.uid) {
              recipientsBuffer.splice(rindex, 1);
              selectedInScopeDisc.push(buffer);
            }
          });

          // If we removed every user from recipients array with "all-except"
          // mode, then we are broadcasting to every user hence the change of
          // mode to "all"
          if (recipientsBuffer.length == 1) {
            recipientsBuffer[0].uid = 'all';
          }
        }
      } else { //remove from selection
        if (recipientsMode == 'all' || recipientsMode == 'select') {
          selectedInScopeDisc.forEach((user, sindex) => {
            if (user.uid == buffer.uid) {
              recipientsBuffer.forEach((recipient, rindex) => {
                if (recipient.uid == buffer.uid) {
                  recipientsBuffer.splice(rindex, 1);
                }
              });
              selectedInScopeDisc.splice(sindex, 1);
            }
          })
        } else if (recipientsMode == 'all-except') {
          recipientsBuffer.push(buffer);
        }
      }
    };

    await this.setState({
      modifiedRecipients: recipientsBuffer
    });
  }

  isChecked(uid) {
    const recipients = this.state.modifiedRecipients;

    if (recipients.length) {

      const recipientsMode = recipients[0].uid;
      const recipientsCopy = recipients.slice();
      recipientsCopy.splice(0, 1); //remove mode
      const target = document.getElementById(uid);

      //special case for select-all button
      if (uid == 'select-all-disc') {
        const participants = this.state.participants;

        if (target) {
          if (!participants.length) {
            document.getElementById('select-all-disc').disabled = 'disabled';
            return false;
          }else {
            document.getElementById('select-all-disc').disabled = false;
          }
        }

        if (recipientsMode == 'all-except') {
          // some() returns true when one of its callbacks returns true(and breaks the loop)
          return !participants.some((participant) => {
            return recipientsCopy.find(user => user.uid == participant.uid)
          });
        } else if (recipientsMode == 'all') {
          return true;
        } else if (recipientsMode != 'empty') {
          let shouldBeChecked = true;
          participants.forEach((participant) => {
            if (!recipientsCopy.find(user => user.uid == participant.uid)) {
              shouldBeChecked = false;
            }
          });
          return shouldBeChecked;
        } else {
          return false;
        }
      }

      if (recipientsCopy) {
        if (recipientsMode == 'all') {
          return true;
        } else if (recipientsMode == 'all-except') {
          if (recipientsCopy.find(user => user.uid == uid)) {
            return false;
          } else {
            return true;
          }
        } else if (recipientsMode == 'select') {
          if (recipientsCopy.find(user => user.uid == uid)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  // Helps disable the checkbox of users not added in ScopeDisc component
  isImmutable(uid) {
    let {selectedInScopeDisc} = this.state;
    let notDisabled = false;
    if (this.isChecked(uid)) {
      selectedInScopeDisc.some((user, index) => {
        if (uid == user.uid) {
          notDisabled = true;
        }
      })

      if (notDisabled) {
        return false;
      }

      return 'disabled';
    }

    return false;

  }

  handleConfirmPopup() {
    document.getElementById('confirm-popup').style.display = 'flex';
  }

  handleExitScopeDisc() {
    this.props.unmountMe();
  }

  handleCancel() {
    document.getElementById('confirm-popup').style.display = 'none';
  }

  handleConfirm() {
    let {modifiedRecipients} = this.state;
    this.props.recipientsProxy(modifiedRecipients);
    this.props.unmountMe();
  }

  handleSearch(event) {
    const searchKeyword = event.target.value;

    let getSearchResults = function() {
      document.getElementById('scope-disc-list').scrollTop = 0;

      this.setState({
        searchKeyword: searchKeyword,
      });

      this.loadSearchedRecords('/discussions', 'discussions', 'uid', searchKeyword);
    }.bind(this);

    let throttledGetSearchResults = debounce(getSearchResults, 500);
    throttledGetSearchResults();
  };

  render() {
    const {
      discussions,
      participants,
      recipients,
      selectedInScopeDisc
    } = this.state;
    return(
      <Wrapper>
        <Card>
          <CardHead>
            <CardTitle>Discussion Scope</CardTitle>
            <CardExtra>
              <i
                className="fa fa-arrow-circle-right"
                onClick={this.handleConfirmPopup}
              />
              <ConfirmPopup id="confirm-popup">
                <PopupWrapper>
                  <span>Are you sure you want to apply selection?</span>
                  <UsersWrapper>
                    {selectedInScopeDisc.map((user, index) =>
                      <ConfirmUser key={user.uid + 'popup'}>+{user.firstName}</ConfirmUser>
                    )}
                  </UsersWrapper>
                  <ButtonWrapper>
                    <Button className="red" onClick={this.handleExitScopeDisc}>Exit</Button>
                    <Button className="grey" onClick={this.handleCancel}>Cancel</Button>
                    <Button className="green" onClick={this.handleConfirm}>Confirm</Button>
                  </ButtonWrapper>
                </PopupWrapper>
              </ConfirmPopup>
            </CardExtra>
          </CardHead>
          <CardContent>
            <ContentWrapper>
              <Discussions>
                <CardTitle>Discussions</CardTitle>
              <DiscussionList
                id="scope-disc-list"
                onScroll={this.handleScrollLoad}
              >
                {discussions.map((disc, index) =>
                  <DiscItem
                    key={disc.uid}
                    id={disc.uid}
                    onClick={this.handleSelectDiscussion}
                  >
                    <DiscHead>
                       <DiscAuthorPic>
                         <img src={disc.authorAvatarLink} alt="" />
                       </DiscAuthorPic>
                       <DiscAuthorName>
                         <span>{disc.authorName}</span>
                       </DiscAuthorName>
                       <DiscCreationDate>
                         {convertUnixTimestamp(disc.creationDate)}
                       </DiscCreationDate>
                     </DiscHead>
                     <DiscContent>
                       {disc.message}
                     </DiscContent>
                  </DiscItem>
                )}
              </DiscussionList>
              <DiscussionSearch>
                <i className="fa fa-search"></i>
                <input type="text" onChange={this.handleSearch} placeholder="Search by uid..."/>
              </DiscussionSearch>
              </Discussions>
              <Recipients>
                <CardTitle>Recipients</CardTitle>
                <RecipientsWrapper>
                  <UserList
                    id="user-list-disc"
                  >
                    <UserItem>
                      <input
                        id="select-all-disc"
                        type="checkbox"
                        onChange={this.handleSelect}
                        checked={
                          this.isChecked('select-all-disc')
                        }
                      />
                      <label htmlFor="select-all-disc">Select All</label>
                    </UserItem>
                    {participants.map((user, index) =>
                      <UserItem key={user.uid}>
                        <input
                          name="user-chk-disc"
                          id={user.uid}
                          type="checkbox"
                          onChange={this.handleSelect}
                          checked={
                            this.isChecked(user.uid)
                          }
                          disabled={
                            this.isImmutable(user.uid)
                          }
                        />
                        <label htmlFor={user.uid}>{user.firstName}</label>
                        <span>{user.uid}</span>
                      </UserItem>
                    )}
                  </UserList>
                </RecipientsWrapper>
              </Recipients>
            </ContentWrapper>
          </CardContent>
        </Card>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
 width: 100vw; height: calc( 100vh - 75px );
 padding: 3em;
 background: #000000CC;
 display: { this.props.show ? 'block' : 'none'}
 position: absolute;
 top: 75px; left: 0;
`

const Discussions = styled.div`
`

const Recipients = styled.div`

`

const DiscussionList = styled.div`
  width: 50vw; height: calc(100vh - 7em - 285px );;
  margin: 35px 15px;
  margin-bottom: 0;
  max-height: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: 100%;
  grid-template-rows: auto;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin: 35px;
`

const DiscItem = styled.div`
  border: 1px solid #eee;
  min-height: 6em;
  display: grid;
  grid-template-columns: 1fr 4fr;
  transition: .2s linear;

  &:hover,
  &.selected {
    border-color: #888;
    cursor: pointer;
    background: #eee;
  }
`

const DiscHead = styled.div`
`

const DiscAuthorPic = styled.div`
  margin: 5px;
  margin-bottom: 0;
  img {
   width: 60px; height: 60px;
   max-width: 3em;
   max-height: 3em;
   background-image: url('https://firebasestorage.googleapis.com/v0/b/tefter-be7a6.appspot.com/o/app%2FanonymousLogo.jpg?alt=media&token=0e6f0b2c-ac8c-4907-b1a9-3c3fb69b4288');
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
   border-radius: 50%;
   object-fit: cover;
  }

`

const DiscAuthorName = styled.div`
  font-size: .85em;
`

const DiscCreationDate = styled.div`
  font-size: .8em;
  color: #666;
`

const DiscContent = styled.div`
  padding: .5em;
  line-height: 1em;
  height: 5.5em; ${'' /* 5 lines + .5em padding */}
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`

const RecipientsWrapper = styled.div`
  margin: 35px 15px;
`

const UserList = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 7em - 285px );
  display: grid;
  grid-auto-rows: 3em;
  grid-row-gap: 5px;
  border: 1px solid #ddd;
`

const UserItem = styled.div`
  height: 3em;
  display: grid;
  grid-template-columns: 3em 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;

  input {
    grid-column: 1/2;
    grid-row: 1/3;
    align-self: center;
    margin: auto;
    cursor: pointer;

  }

  input:checked ~ * {
    color: teal;
  }

  input:checked ~ *:hover {
    color: white;
  }

  label {
    grid-column: 2/3;
    grid-row: auto;
  }

  span {
    grid-row: auto;
    grid-column: 2/3;
    font-size: .7em;
  }

  ${UserItem}:first-child label {
    grid-row: 1/3;
    align-self: center;
  }

  ${UserItem}:hover {
    background: #04b5b5;
    color: white !important;
  }
`

const ConfirmPopup = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; height: 100%;

  font-size: 1rem;

  align-items: center;
  justify-content: center;
`
const UsersWrapper = styled.div`
  margin: 20px 0;
`

const ConfirmUser = styled.div`
  &::first-letter {
    font-weight: bold;
    color: limegreen;
  }
`

const PopupWrapper = styled.div`
  background: white;
  width: 30vw; height: auto;
  border: 1px solid #a9a9a9;
  padding: 35px;

  span {
    font-weight: bold;
  }
`

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  margin-top: 15px;
`

const Button = styled.div`

  color: #333;
  cursor: pointer;

  &.red {
    background: #f15151;
  }

  &.grey {
    background: lightgrey;
  }

  &.green {
    background: #64d064;
  }
`


const DiscussionSearch = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 50vw; height: 2em;
  padding: 0 15px;

  i {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 2em;
    font-size: .8em;
    color: teal;
  }

  input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 2em;
    border: 1px solid #ddd;
    min-height: 2em;
  }
`

import React from 'react';
import {Route, Redirect, Switch, Link} from 'react-router-dom';

import styled from 'styled-components';
import firebase from 'firebase';

import {Card, CardHead, CardTitle, CardExtra, CardContent} from '../helpers/Card';
import {TextField, TextArea} from '../helpers/Inputs';

import Text from './Types/Text';
import Disc from './Types/Disc';
import Evo from './Types/Evo';
import Scope from './Scope';

export default class Notification extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      notificationType: 'adminMassMessage',
      sendInAppNotif: true,
      sendPushNotif: true,
      numberOfContentTypes: 0,
      contentType: this.props.match.params.type,
      discussionUid: '',
      commentUid: '',
      notificationTitle: '',
      notificationContent: '',
      recipients: [],
      namedRecipients: [],
      anonymous: true,
      showRecipients: false,
      presetRecipient: this.props.match.params.uid
    }

    this.handleContentTypeChange = this.handleContentTypeChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.gatherContent = this.gatherContent.bind(this);
    this.gatherDisc = this.gatherDisc.bind(this);
    this.gatherEvo = this.gatherEvo.bind(this);
    this.gatherRecipients = this.gatherRecipients.bind(this);
    this.handleNotificationTypeChange = this.handleNotificationTypeChange.bind(this);
    this.handleSendNotification = this.handleSendNotification.bind(this);
    this.handleAnonChange = this.handleAnonChange.bind(this);
    this.toggleRecipients = this.toggleRecipients.bind(this);
  }

  componentDidMount() {
    const tabs = document.getElementsByClassName('content-type');
    this.setState({
      numberOfContentTypes: tabs.length
    })

    const type = this.state.contentType;
    const currentTab = document.getElementById(type);
    currentTab.style.color = 'black';
    currentTab.style.border = '1px solid rgb(238, 238, 238)';


    const {presetRecipient} = this.state;
    if (presetRecipient) {
      let recipients = [];
      let namedRecipients = [];
      recipients[0] = 'select';
      namedRecipients[0] = {firstName: null, uid: 'select'};
      recipients.push(presetRecipient);
      namedRecipients.push({firstName: 'placeholder', uid: presetRecipient});
      this.setState({
        recipients: recipients,
        namedRecipients: namedRecipients
      })
    }
  }

  handleContentTypeChange(event) {
    const {
      numberOfContentTypes,
      contentType
    } = this.state;

    const tabs = document.getElementsByClassName('content-type');
    const target = event.target;
    const percentage = 100/numberOfContentTypes;

    for (let i=tabs.length; i>0; i--) {
      tabs[i-1].style.color = '#a9a9a9';
      tabs[i-1].style.border = 'none';
      tabs[i-1].style.background = '#eeeeee00'
    }

    target.style.color = 'black';
    target.style.background = '#eeeeee45'
    target.style.border = '1px solid rgb(238, 238, 238)';

    this.setState({
      sendInAppNotif: true,
      sendPushNotif: true,
      contentType: target.id,
      discussionUid: '',
      notificationContent: '',
      anonymous: true
    });
  }

  handleTitleChange(title) {
    this.setState({
      notificationTitle: title
    });
  };

  gatherContent(content) {
    this.setState({
      notificationContent: content
    });
  };

  gatherDisc(uid) {
    this.setState({
      discussionUid: uid
    });
  }

  gatherEvo(uid) {
    this.setState({
      commentUid: uid
    });
  }

  gatherRecipients(recipients, namedRecipients) {
    this.setState({
      recipients: recipients,
      namedRecipients: namedRecipients
    });
  };

  handleNotificationTypeChange(event) {
    const checked = event.target.checked;
    const type = event.target.id;
    if (type === 'inapp') {
      this.setState({
        sendInAppNotif: checked
      });
    }else if (type === 'push') {
      this.setState({
        sendPushNotif: checked
      });
    }
  }

  handleAnonChange(event) {
    let anonymous = this.state.anonymous;
    this.setState({
      anonymous: !anonymous
    })
  }

  async handleSendNotification(event) {
    const {
      notificationType,
      sendInAppNotif,
      sendPushNotif,
      notificationTitle,
      discussionUid,
      commentUid,
      contentType,
      notificationContent,
      recipients,
      anonymous
    } = this.state;

    const currentUser = this.props.currentUser;

    //Clone recipients array, so we don't modify the original
    const newRecipients = recipients.slice();

    let admin = {};
    await firebase.database().ref('/users')
      .child(currentUser.uid)
      .once('value').then((snapshot) => {
        admin = snapshot.val();
      });

    const recipientsMode = recipients[0];
    newRecipients.splice(0, 1); //remove mode element
    const creationDate = (new Date).getTime();

    if (contentType == 'text' && (!notificationTitle || !notificationContent)) {
      alert("Title and Message can't be empty!");
      return;
    }

    if (contentType == 'disc' && (!notificationTitle || !discussionUid)) {
      alert("Title and Discussion can't be empty!");
      return;
    }

    if (recipientsMode == 'empty' || (newRecipients.length == 0 && recipientsMode != 'all')) {
      alert("You must select recipient(s)!");
      return;
    }

    const newNotificationUid = firebase.database().ref().child('pendingNotifications').push().key;
    const newNotification = {
      anonymous: anonymous,
      authorAvatarLink: admin ? admin.avatar : currentUser.photoURL,
      authorName: admin ? admin.firstName : currentUser.displayName,
      authorUid: currentUser.uid,
      creationDate: creationDate,
      discussionUid: discussionUid,
      commentUid: commentUid,
      title: notificationTitle,
      message: notificationContent,
      notificationType: 'adminMassMessage', // TODO: change notif type for disc 'rateUpDiscussion'
      sendInAppNotif: sendInAppNotif,
      sendPushNotif: sendPushNotif,
      recipientsMode: recipientsMode,
      recipients: newRecipients,
      uid: newNotificationUid
    }

    if (!confirm(
      'You are about to send notification with current params:\n'+
      'anonymouse: '+anonymous+'\n'+
      'recipientsMode: '+recipientsMode+'\n'+
      'sendInAppNotif: '+sendInAppNotif+'\n'+
      'sendPushNotif: '+sendPushNotif+'\n')) {
      return;
    }

    firebase.database()
    .ref('/pendingNotifications/' + newNotificationUid)
    .update(newNotification)
    .then(() => {
      alert('Notification Sent! UID: '+newNotificationUid);
    })
  }

  toggleRecipients() {
    const currFlag = this.state.showRecipients;
    this.setState({
      showRecipients: !currFlag
    })
  }

  render(){

    const {
      anonymous,
      sendInAppNotif,
      sendPushNotif,
      notificationType,
      numberOfContentTypes,
      contentType,
      recipients,
      namedRecipients,
      showRecipients
    } = this.state;

    const type = this.props.type;

    return(
      <Wrapper>
        <Card>
          <CardHead>
            <CardTitle>
              Notification
            </CardTitle>
            <CardExtra>
              <i
                className="fa fa-user"
                onClick={this.toggleRecipients}
              />
              
              {recipients.length > 0 && recipients[0] != 'empty'
                ? <span className="recipients-count">
                    {recipients.length-1}
                  </span>
                : null}
              
            </CardExtra>
          </CardHead>
          <CardContent>
            <NotificationWrapper>
              <NotificationTitle>
                <TextField
                  placeholder="Title"
                  proxy={this.handleTitleChange}
                  big
                />
              </NotificationTitle>
              <NotificationContentWrapper>
                <ContentTypeTabs
                  numberOfContentTypes={numberOfContentTypes}
                >
                  <section>
                    <Link
                      to="/notification/text"
                      className="content-type"
                      id="text"
                      onClick={this.handleContentTypeChange}
                    >
                      TEXT/HTML
                    </Link>
                    <Link
                      to="/notification/disc"
                      className="content-type"
                      id="disc"
                      onClick={this.handleContentTypeChange}
                    >
                      DISC
                    </Link>
                    <Link
                      to="/notification/evo"
                      className="content-type"
                      id="evo"
                      onClick={this.handleContentTypeChange}
                    >
                      EVO
                    </Link>
                  </section>
                </ContentTypeTabs>
                <NotificationContent>
                  <Switch>
                    <Route path="/notification/text" render={() => (
                      <Text onSend={this.gatherContent} />
                    )} />
                    <Route path="/notification/disc" render={() => (
                      <Disc onSelect={this.gatherDisc}/>
                    )} />
                    <Route path="/notification/evo" render={() => (
                      <Evo onSelect={this.gatherEvo} />
                    )} />
                    <Redirect from='/notification' to='/notification/text' />
                  </Switch>
                </NotificationContent>
                <NotificationControls>
                  <TextFormatting>
                    <FormattingOption>
                      <input id="bold" type="checkbox" />
                      <label htmlFor="bold" className="fas fa-bold" />
                    </FormattingOption>
                    <FormattingOption>
                      <input type="checkbox" />
                      <i className="fas fa-italic"></i>
                    </FormattingOption>
                    <FormattingOption>
                      <input type="checkbox" />
                      <i className="fas fa-underline"></i>
                    </FormattingOption>
                  </TextFormatting>
                  <NotificationType>
                    <TypeOption>
                      <label htmlFor="inapp">In-App</label>
                      <input
                        type="checkbox"
                        id="inapp"
                        onChange={this.handleNotificationTypeChange}
                        checked={sendInAppNotif}
                      />
                    </TypeOption>
                    <TypeOption>
                      <label htmlFor="push">Push</label>
                      <input
                        type="checkbox"
                        id="push"
                        onChange={this.handleNotificationTypeChange}
                        checked={sendPushNotif}
                      />
                    </TypeOption>
                  </NotificationType>
                  <AnonOption
                    onClick={this.handleAnonChange}
                  >
                    <i
                      className={
                        anonymous ? 'fa fa-user selected' : 'fa fa-user'
                      }
                    />
                    <span
                      className={
                        anonymous ? 'selected' : ''
                      }
                    >
                      Anonymous
                    </span>
                  </AnonOption>
                  <SendButton onClick={this.handleSendNotification}>
                    <i className="fa fa-paper-plane"></i>
                  </SendButton>
                </NotificationControls>
              </NotificationContentWrapper>
            </NotificationWrapper>
          </CardContent>
        </Card>
        <Scope
          proxy={this.gatherRecipients}
          recipients={recipients}
        />
        {showRecipients
          ? <RecipientsListWrapper
              show={showRecipients}
              onClick={this.toggleRecipientst}
            >
              <RecipientsList
                usersCount={namedRecipients.length}
              >
                <i className="fa fa-times close-btn" onClick={this.toggleRecipients}></i>
                {namedRecipients.map((user) =>
                  <Recipient
                    key={user.uid+'list'}
                  >
                    {user.firstName
                      ? <span> name: {user.firstName} </span>
                      : 'mode: '+user.uid
                    }
                    {user.firstName
                      ? <span> uid: {user.uid} </span>
                      : null
                    }
                  </Recipient>
                )}
              </RecipientsList>
            </RecipientsListWrapper>
          : null}
      </Wrapper>
    )
  }
}

/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const Wrapper = styled.div`
  margin: 35px;
  display: grid;
  grid-template-columns: 4fr 1.7fr;
  grid-column-gap: 35px;
  
  .recipients-count {
    position: absolute;
    border-radius: 50%;
    background: #ff1313b0;
    width: 1rem;
    height: 1rem;
    font-size: .6rem;
    line-height: 1rem;
    text-align: center;
  }
`

const NotificationWrapper = styled.div`
  padding: 35px;
  display: grid;
  grid-template-rows: 3em 6fr;
  grid-row-gap: 35px;

`

const NotificationTitle = styled.div`
  background: lightblue;
`

const NotificationContentWrapper = styled.div`
  display: grid;
  grid-template-rows: 10% 1fr;
  grid-row-gap: 1em;
  ${'' /* padding: 0 15px; */}
`

const NotificationContent = styled.div`
`

const ContentTypeTabs = styled.div`
  display: grid;
  grid-template-rows: 1fr 2px;

  section {
    height: 100%;
    border-bottom: 1px solid rgb(238, 238, 238);
    display: grid;
    grid-template-columns: repeat(${props => props.numberOfContentTypes}, 1fr);

    .content-type {
      height: 100%; width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: center;
      font-size: .8em;
      text-decoration: none;
      color: #a9a9a9;
      cursor: pointer;
      transition: color .2s linear;
      border-bottom: none !important;
    }
  }

  #track {
    position: relative;
    height: 2px;
    width: 100%;

    #tracer {
      position: absolute;
      width: ${props => 100/props.numberOfContentTypes+'%'}; height: 100%;
      left: 0;
      background: teal;
      transition: left 0.5s cubic-bezier(.95,-0.16,.11,1.13) 0s;
    }
  }
`

const NotificationControls = styled.div`
  position: relative;
  width: 100%; height: 100%;
  display: grid;
  grid-template-columns: 20% 1fr 1fr 3em;
`

const TextFormatting = styled.div`
`

const FormattingOption = styled.div`
  float: left;
  width: 33%; height: 100%;
  border: 1px solid #00000011;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background .2s linear;

  i {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  input {
    display: none;
  }

  input:checked + i {
    background: #00000011;
  }
`

const NotificationType = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`

const TypeOption = styled.div`
  float: left;
  text-align: center;
`

const AnonOption = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #ccc;

  span {
    width: 100%;
    text-align: center;
  }

  i:hover,
  i:hover + span {
    color: #00808080;
    cursor: pointer;
    transition: color .2s linear;
  }

  .selected:hover,
  i.selected:hover + span {
    color: teal;
  }

  .selected {
    color: teal;
  }
`
// TODO: Add classes .clickable and .centered

const SendButton = styled.div`
  color: teal;
  border: 1px solid #00000011;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color .2s linear;

  ${SendButton}:hover {
    cursor: pointer;
    color: #008080ab;
  }
`

const RecipientsListWrapper = styled.div`
  width: 100vw; height: calc( 100vh - 75px );
  padding: 3em;
  background: #000000CC;
  display: block;
  position: absolute;
  top: 75px; left: 0;

`

const RecipientsList = styled.div`
  width: 35vw; height: calc( ${props => (props.usersCount-1) * 4.5 + 2 + 'em'} + 70px );
  max-height: calc( 100vh - 150px );
  margin: auto;
  padding: 35px;
  background: white;
  display: grid;
  grid-row-gap: 2em;
  grid-template-rows: 1em auto;
  overflow-y: auto;
  position: relative;

  .close-btn {
    width: 1.5rem; height: 1.5rem;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1.5em;
    text-align: center;
  }

  .close-btn:hover {
    color: teal;
    cursor: pointer;
  }
`

const Recipient = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid lightgrey;

  &:first-of-type {
    grid-template-rows: 1fr;
    font-weight: bold;
    border: none;
  }
`


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

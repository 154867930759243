import React from 'react';

import styled from 'styled-components';
import firebase from 'firebase';
import firebaseui from 'firebaseui';

// TODO: ENV VARS
// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ]
};


export default class Auth extends React.Component {
  componentDidMount() {
    const ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#auth', uiConfig); // TODO: Fix re-render issue
  }

  render() {
    return(
      <AuthWrapper>
        <WelcomeTitle>Welcome</WelcomeTitle>
        <div id="auth"></div>
      </AuthWrapper>
    );
  }
}


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const AuthWrapper = styled.div`
  height: auto;
  display: grid;
  grid-template-rows: 20% 1fr;
  justify-content: center;
  align-items: center;
  align-self: center;
`

const WelcomeTitle = styled.h1`
  float: left;
  clear: both;
  text-align: center;
`
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react';

import styled from 'styled-components';

import {TextArea} from '../../helpers/Inputs';

export default class Text extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }

    this.handleChangeProxy = this.handleChangeProxy.bind(this);
  }

  handleChangeProxy(content){
    this.props.onSend(content);
  }

  render() {
    return (
      <Wrapper>
        <NotificationContent>
          <TextArea onContentChanged={this.handleChangeProxy}/>
        </NotificationContent>
      </Wrapper>
    );
  }
}


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */

const Wrapper = styled.div`
  width: 100%; height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 1em;
`

const NotificationContent = styled.div`
`

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react';

import styled from 'styled-components';
import firebase from 'firebase';

import ScopeUsers from './ScopeUsers';
import ScopeDisc from './ScopeDisc';
import ScopeUsersOptions from './ScopeUsersOptions';
import {Card, CardHead, CardTitle, CardExtra, CardContent} from '../../helpers/Card';

export default class Scope extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipients: [],
      scopeUsersParams: {
        orderBy: 'firstName',
        usersPerLoad: 25
      },
      scopeReset: false,
      discScopeShow: false
    };

    this.getSelectedRecipients = this.getSelectedRecipients.bind(this);
    this.getScopeUsersParams = this.getScopeUsersParams.bind(this);
    this.selectFromDiscussions = this.selectFromDiscussions.bind(this);
    this.gatherRecipients = this.gatherRecipients.bind(this);
    this.handleUnmountScopeDisc = this.handleUnmountScopeDisc.bind(this);
  }

  getSelectedRecipients(recipients){
    let namedRecipients = [];
    recipients.forEach((recipient) => {
      let buffer = {
        firstName: '',
        uid: recipient,
      }
      firebase.database().ref('/users/'+recipient)
      .child('firstName')
      .once('value', (snapshot) => {
        buffer.firstName = snapshot.val();
        namedRecipients.push(buffer);
      })
    });
    this.setState({
      recipients: namedRecipients
    })
  }

  getScopeUsersParams(params) {
    this.setState({
      scopeUsersParams: params,
      scopeReset: true
    });
  }

  toggleOptions(event) {
    event.preventDefault();

    const wrapper = document.getElementById('scope-options');

    if (wrapper.classList.contains('visible')) {
      if (event.target.id != 'scope-options') {
        return;  //Prevents closing when clicking on the options component
      }
      wrapper.classList.remove('visible');
    }else {
      wrapper.classList.add('visible');
    }
  }

  async selectFromDiscussions(event) {
    event.preventDefault();
    const discScopeShow = this.state.discScopeShow;
    await this.setState({
      discScopeShow: !discScopeShow
    })
  }

  componentDidUpdate() {
    const reset = this.state.scopeReset;
    if (reset) {
      this.setState({
        scopeReset: false
      });
    }
  }

  gatherRecipients(recipients) {
    this.setState({
      recipients: recipients
    });

    let strippedRecipients = [];
    recipients.forEach((recipient) => {
      strippedRecipients.push(recipient.uid);
    });
    this.props.proxy(strippedRecipients, recipients);
  }

  handleUnmountScopeDisc() {
    this.setState({
      discScopeShow: false
    })
  }

  render() {
    const {
      recipients,
      scopeUsersParams,
      scopeReset,
      discScopeShow
    } = this.state;

    return (
      <Wrapper>
        <Card>
          <CardHead>
            <CardTitle>
              Scope
            </CardTitle>
            <CardExtra>
              <ScopeOptions className="fa fa-cogs" onClick={this.toggleOptions}>
                <OptionsWrapper id="scope-options">
                  <ScopeUsersOptions
                    sendParams={this.getScopeUsersParams}
                    currParams={scopeUsersParams}
                  />
                </OptionsWrapper>
              </ScopeOptions>
            </CardExtra>
          </CardHead>
          <CardContent>
            <ScopeWrapper>
              <ScopeSelection>
                <ScopeUsers
                  recipientProxy={this.props.proxy}// TODO: naming
                  gatherData={this.getSelectedRecipients}
                  params={scopeUsersParams}
                  reset={scopeReset}
                  recipientsFromParent={recipients}
                />
              </ScopeSelection>
              <DiscussionScopeBtn onClick={this.selectFromDiscussions}>
                Select users from discussions
              </DiscussionScopeBtn>
            </ScopeWrapper>
          </CardContent>
        </Card>
        {discScopeShow ? <ScopeDisc
                           recipients={recipients}
                           recipientsProxy={this.gatherRecipients}
                           unmountMe={this.handleUnmountScopeDisc}
                         />
                         : null}
      </Wrapper>
    );
  }
}


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const Wrapper = styled.div`
`

const ScopeOptions = styled.div`
  transition: color .1s linear;
  color: #aaa;

  &:hover {
    cursor: pointer;
    color: teal;
  }
`

const OptionsWrapper = styled.div`
display: none;
position: absolute;
width: 100vw; height: 100vh;
top: 0; left: 0;
z-index: 1;
background: #00000080;
align-items: center;
justify-content: center;

&.visible {
  display: flex
}
`

const ScopeWrapper = styled.div`
  margin: 35px;
  grid-row-gap: 15px;
  display: grid;
  grid-template-rows: 1fr 3em;
`

const DiscussionScopeBtn = styled.div`
  width: 100%; height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  transition: border .2s linear,
              color .2s linear;

  &:hover {
    border: 1px solid teal;
    color: teal;
    cursor: pointer;
  }
`

const ScopeSelection = styled.div`

`

const SelectedRecipientsList = styled.div`
  position: absolute;
  z-index: 1;
  top: -35px;
  width: 100%;
  background: #f1f1f1;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25),
              0 10px 10px rgba(0,0,0,0.22);

  & > div {
    font-size: .7em;
    padding: 10px;
    max-height: 50vh;
    overflow-y: scroll;
  }

  &.hovered {
    display: none;
  }

  ${SelectedRecipientsList}:before {
    content: '';
  	position: absolute;
  	top: -1.25em;
  	right: 0;
  	width: 0;
  	height: 0;
  	border: 1.25em solid transparent;
  	border-bottom-color: #f1f1f1;
  	border-top: 0;
  	border-right: 0;
  }
`

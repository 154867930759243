import React from 'react';

import styled from 'styled-components';

import firebase from 'firebase';
import {convertUnixTimestamp} from '../helpers/Utils';

export default class Notices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notices: [],
            newNoticeMessage: '',
        };
        this.handleNoticeChange = this.handleNoticeChange.bind(this);
        this.sendNotice = this.sendNotice.bind(this);
    }

    async componentDidMount() {
        await firebase.database().ref('evoAdminNotices/')
        .once('value')
        .then((noticesSnapshot) => {
            let notices = [];
            noticesSnapshot.forEach(
                (notice) => {
                    notices.unshift(notice.val())
                }
            );
            this.setState({
                notices: notices
            })
        });
    }

    async handleNoticeChange(event) {
        let message = event.target.value;
        await this.setState({
            newNoticeMessage: message
        })
    }

    sendNotice(event) {
        const creationDate = (new Date).getTime();
        const newNoticeUid = firebase.database().ref().child('evoAdminNotices').push().key;
        const newNoticeMessage = this.state.newNoticeMessage;
        const currentUser = this.props.currentUser; 

        const newNotice = {
            message: newNoticeMessage,
            uid: newNoticeUid,
            creationDate: creationDate,
            author: currentUser.displayName,
            authorPic: currentUser.photoURL
        }

        firebase.database()
        .ref('/evoAdminNotices/' + newNoticeUid)
        .update(newNotice)
        .then(() => {
          firebase.database().ref('evoAdminNotices/')
            .once('value')
            .then((noticesSnapshot) => {
              let notices = [];
              noticesSnapshot.forEach(
                (notice) => {
                  notices.unshift(notice.val())
                }
              );
              this.setState({
                notices: notices
              })
              document.getElementById('input-box').value = '';
            });
        })
    }

    render() {
        let notices = this.state.notices;
        return (
            <Wrapper>
                <NoticesWrapper>
                    {notices.map((notice, index) => 
                        <Notice
                            key={index}
                        >
                          <NoticeAuthor>
                            <img src={notice.authorPic} alt=""/>
                            <span className="author-name">{notice.author.slice(0, 15)}</span>
                            <span className="creation-date">{convertUnixTimestamp(notice.creationDate)}</span>
                          </NoticeAuthor>
                          <NoticeMessage>
                            <div className="message" id={index+"message-box"}>
                              {notice.message}
                            </div>
                            { notice.message.length > 100
                              ? <div
                                  className="tooltiptext"
                                  id={index + "tooltip-box"}
                                >
                                  {notice.message}
                                </div>
                              : null
                            }
                          </NoticeMessage>
                        </Notice>
                    )}
                </NoticesWrapper>
                <InputWrapper>
                    <input type="text" id="input-box" onBlur={this.handleNoticeChange}/>
                    <i className="fa fa-paper-plane" onClick={this.sendNotice}></i>
                </InputWrapper>
            </Wrapper>
        )
    }
}

/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const Wrapper = styled.div`
`
const NoticesWrapper = styled.div`
    padding: 5px;
    display: grid;
    grid-auto-rows: 3.8em;
    grid-row-gap: 5px;
    height: calc(100vh - 75px - 70px - 4em - 2em);
    overflow-y: scroll;
`

const InputWrapper = styled.div`
  position: relative;
  display: grid;

  input {
    height: 1.5em;
    align-self: center;
    width: calc(100% - 2rem);
    margin-left: 5px;
    margin-top: 5px;
    padding-left: 5px;
  }

  i {
    align-self: center;
    position: absolute;
    text-align: center;
    font-size: 1.2rem;
    width: 2rem;
    right: 0;
    transition: .2s color linear;
  }

  i:hover {
    cursor: pointer;
    color: teal;
  }
`

const Notice = styled.div`
  border: 1px solid #ddd;
  width: 100%; height: 3.5em;
  display: grid;
  grid-template-columns: 20ch 1fr;
  grid-column-gap: 5px;
`
    
const NoticeAuthor = styled.div`
  width: 20ch;
  display: grid;
  grid-template-columns: 3.5em 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 5px;
  border-right: 1px solid #ddd;

  img {
    width: 3.5em; height: 3.5em;
    grid-row: 1/3;
  }

  .author-name {
    font-size: .9em;
    grid-row: 1/2;
  }

  .creation-date {
    grid-row: 2/3;
    font-size: .8em;
    color: #bfbfbf;
  }
`
    
const NoticeMessage = styled.div`
  .message {
    position: relative;
    height: 100%;
    line-height: 1.15em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .tooltiptext {
    width: 40ch;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 1px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  &:hover .tooltiptext {
    visibility: visible;
    transition: 1s visibility linear;
  }
`
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react';

import styled from 'styled-components';
import firebase from 'firebase';

export default class Header extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      versionName: 'N/A',
    }
  }

  componentDidMount() {
    firebase.database().ref('systemSettings/android/releaseInfo/versionName')
    .on('value', function(snapshot) {
      this.setState({
        versionName: snapshot.val()
      })
    }.bind(this));
  }

  handleSignOut(event) {
    event.preventDefault();
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
      // window.location = 'http://localhost:1234/#/auth'; // TODO: fix redirect
    }).catch(function(error) {
      // An error happened.
    });
  }

  render() {
    const {
      versionName
    } = this.state

    const {
      currentUser
    } = this.props;

    return(
      <HeaderWrapper>
        <Title>Evo<span>Admin</span></Title>
        <VersionName>
          {versionName}
        </VersionName>
        <AdminWrapper>
          <a>
            <i className="fa fa-bell"></i>
          </a>
          <a onClick={this.handleSignOut}>
            <i className="fa fa-sign-out-alt"></i>
          </a>
          <AdminPic>
            <img src={currentUser.photoURL} alt=""/>
          </AdminPic>
        </AdminWrapper>
      </HeaderWrapper>
    );
  }
};


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const HeaderWrapper = styled.section`
width: 100%; height: 75px;
z-index: 5;
background: #0c2733;
color: white;

display: grid;
grid-template-columns: 20% 1fr auto 1fr auto;
align-content: center;
`

const Title = styled.h1`
  padding-left: 15px;
  margin: 0;
  color: white;
  font-size: 2em;
  font-weight: bold;
  align-self: center;

  span {
    color: teal;
  }
`

const AdminWrapper = styled.div`
  grid-column: -2/-1;
  float: right;
  min-width: 200px;
  max-width: 50%;
  display: grid;
  grid-template-columns: repeat( 3, 1fr );

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: teal;
  }

  a:hover {
    cursor: pointer;
    color: #164458;
  }
`
const AdminPic = styled.div`
  width: 60px;
  img {
    width: 50px; height: 50px;
    border-radius: 100%;
  }
`

const VersionName = styled.div`
  grid-column: 3/4;
  display: flex;
  align-items: center;
  color: teal;
`
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

import React from 'react';
import {Link} from 'react-router-dom';

import styled from 'styled-components';
import firebase from 'firebase';

import {Card, CardHead, CardTitle, CardExtra, CardContent} from '../helpers/Card';
import {loadRecordsReversed, loadNewRecordsPageReversed, loadSearchedRecords, convertUnixTimestamp, debounce} from '../helpers/Utils';


export default class Discussions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discussions: [],
      orderBy: 'orderIndex',
      stopLoader: true,
      lastKey: '',
      discussionsPerLoad: 15,
    };
    this.handleScrollLoad = this.handleScrollLoad.bind(this);
    this.loadRecordsReversed = loadRecordsReversed.bind(this);
    this.loadNewRecordsPageReversed = loadNewRecordsPageReversed.bind(this);
    this.loadSearchedRecords = loadSearchedRecords.bind(this);
    this.handleImageFocus = this.handleImageFocus.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    const orderBy = this.state.orderBy;
    this.loadRecordsReversed('/discussions', 'discussions', orderBy);
  }

  handleScrollLoad(){
    const container = document.getElementById('discussions-list');
    const viewportHeight = container.clientHeight;
    const pageHeight = container.scrollHeight;
    const scrollPos = container.scrollTop;


    if (scrollPos == pageHeight - viewportHeight) {
      const {
        discussions,
        discussionsPerLoad,
      } = this.state;


      let startAtUid = discussions[discussions.length-1].uid;
      this.loadNewRecordsPageReversed('/discussions',
                              'discussions',
                              'orderIndex',
                              null,
                              startAtUid,
                              discussionsPerLoad,
                              discussions);
    }
  }

  handleImageFocus(event) {
    event.preventDefault();
    const imgUrl = event.target.currentSrc;
    const rootContainer = document.getElementById('discussions-list');

    const imgContainer = document.createElement('div');
    imgContainer.setAttribute('id', 'focused-image');

    const image = document.createElement('img');
    image.setAttribute('src', imgUrl);
    imgContainer.appendChild(image);

    const closeBtn = document.createElement('button');
    closeBtn.innerText = 'X';
    closeBtn.addEventListener('click', () => {
      rootContainer.removeChild(imgContainer);
    })
    imgContainer.appendChild(closeBtn);

    rootContainer.appendChild(imgContainer);
  }

  handleSearch(event) {
    const searchKeyword = event.target.value;

    let getSearchResults = function() {
      document.getElementById('discussions-list').scrollTop = 0;

      this.setState({
        searchKeyword: searchKeyword,
      });

      const orderBy = this.state.orderBy;
      this.loadSearchedRecords('/discussions', 'discussions', 'uid', searchKeyword);
    }.bind(this);

    let throttledGetSearchResults = debounce(getSearchResults, 500);
    throttledGetSearchResults();
  };


  render() {
    return(
      <Wrapper>
        <Card>
          <CardHead>
            <CardTitle>Discussions</CardTitle>
          </CardHead>
          <CardContent>
            <DiscWrapper
              id="discussions-list"
              onScroll={this.handleScrollLoad}
            >
              {this.state.discussions.map((disc, i) =>
                <Disc key={disc.uid}>
                  <DiscAuthor>
                    <AuthorPic>
                      <img src={disc.authorAvatarLink} alt=""/>
                    </AuthorPic>
                    <AuthorName>
                      <Link to={'/users/' + disc.authorUid}>
                        {disc.authorName}
                      </Link>
                    </AuthorName>
                    <DiscDate>
                      {convertUnixTimestamp(disc.creationDate)}
                    </DiscDate>
                  </DiscAuthor>
                  <DiscMessage>
                    {disc.message}
                  </DiscMessage>
                  {disc.questionImageUrl
                  ? <DiscImage
                      onClick={this.handleImageFocus}
                    >
                      <img src={disc.questionImageUrl} alt=""/>
                    </DiscImage>
                  : null}
                  <EditButton>
                    <Link to={'/discussions/' + disc.uid}>
                      Edit
                    </Link>
                  </EditButton>
                </Disc>
              )}
            </DiscWrapper>
          </CardContent>
        </Card>
        <Card>
          <CardHead>
            <CardTitle>Filter</CardTitle>
          </CardHead>
          <CardContent>
            Empty/Reserved for Tag/User/Date filtering options
            <DiscussionSearch>
              <i className="fa fa-search"></i>
              <input type="text" onChange={this.handleSearch} placeholder="Search by uid..."/>
            </DiscussionSearch>
          </CardContent>
        </Card>
      </Wrapper>
    );
  }
};

// module.exports = Discussions;

/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */

const Wrapper = styled.div`
  margin: 35px;
  display: grid;
  grid-template-columns: 4fr 1.7fr;
  grid-column-gap: 35px;
`

const DiscWrapper = styled.div`
  position: relative;
  width: 100%; height: 100%;
  max-height: calc( 100vh - 75px - 70px - 4em );
  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: auto;

  #focused-image {
    position: fixed;
    top: 75px; left: 0;
    width: 100%; height: calc( 100% - 75px );
    background: #000000dd;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 50vw;
      max-height: 50vh;
      margin: auto;
    }

    button {
      position: absolute;
      top: 20px; right: 20px;
      width: 2.5em; height: 2.5em;
      font-size: 2.5em;
      border: none;
      color: white;
      background: none;
      transition: color .15s linear;
    }

    button:hover {
      cursor: pointer;
      color: teal;
    }
  }
`

const Disc = styled.div`
  width: 50vw; height: auto;
  margin: auto;
  margin-top: 35px;
  padding-left: 2em;
  border-left: 1px solid #2fc6c6;
  display: grid;
  grid-template-columns: 40vw;
  grid-row-gap: 20px;

  a {
    text-decoration: none;
    color: teal;
  }

  a:hover {
    text-decoration: underline;
  }
`

const DiscMessage = styled.div`
  overflow-wrap: break-word;
`

const DiscAuthor = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 60px 1fr;
  width: 100%;
  height: 60px;
`

const AuthorPic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  grid-row: 1/3;

  img {
    width: 60px; height: 60px;
    margin: auto;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/tefter-be7a6.appspot.com/o/app%2FanonymousLogo.jpg?alt=media&token=0e6f0b2c-ac8c-4907-b1a9-3c3fb69b4288);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
    object-fit: cover;
  }
`

const AuthorName = styled.div`
  display: inline;
  padding-left: 10px;
  font-size: 1.5em;
`

const DiscDate = styled.div`
  grid-row: 2/3;
  grid-column: 2/3;
  font-size: .8em;
  color: grey;
  padding-left: 10px;
  display: flex;
  align-items: center;
`

const EditButton = styled.div`
  width: 100px;
  height: 1.5em;
  background: teal;
  text-align: center;
  transition: background .2s linear;

  a {
    display: block;
    color: white;
  }

  a:hover {
    text-decoration: none;
  }

  ${EditButton}:hover {
    background: #0db4b4;
  }
`
const DiscImage = styled.div`
  width: 0;

  img {
    max-width: 20vw;
    max-height: 30vh;
    transition: opacity .1s linear;
  }

  img:hover {
    opacity: .8;
    cursor: pointer;
  }
`

const DiscussionSearch = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 2em;
  padding: 0 20px;

  i {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 2em;
    font-size: .8em;
    color: teal;
  }

  input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 2em;
    border: 1px solid #ddd;
    min-height: 2em;
  }
`

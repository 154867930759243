import React from 'react';

import styled from 'styled-components';

export class TextField extends React.Component {
  constructor(props){
    super(props);
    this.state = {

    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event){
    let title = event.target.value;
    this.props.proxy(title);
    // console.log("Second Step:" + content);
  }

  render() {
    const {
      placeholder,
      big
    } = this.props;

    const uid = guidGenerator();

    return(
      <StyledTextField
        big={big}
      >
        <input
          id={uid}
          type="text"
          placeholder={placeholder}
          onBlur={this.handleChange}
        />
      </StyledTextField>
    )
  }
}

export class TextArea extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      uid: '',
      content: ''
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      uid: guidGenerator()
    })
  }

  handleChange() {
    const textarea = document.getElementById(this.state.uid);
    this.props.onContentChanged(textarea.value);
  }

  render() {
    const {
      placeholder
    } = this.props;

    const {
      uid
    } = this.state;

    return(
      <StyledTextArea>
        <label htmlFor={uid}></label>
        <textarea id={uid} onBlur={this.handleChange} placeholder="Message..."></textarea>
      </StyledTextArea>
    )
  }
}

const StyledTextField = styled.div`
  position: relative;
  width: 100%; height: 100%;

  label {
    position: absolute;
    top: calc(50% - 0.5em);
    left: 0;
  }

  input:focus + label {
    top: 0;
    font-size: .6em;
  }

  input {
    outline: none;
    border: 1px solid #00000011;
    width: 100%; height: 100%;
    font-size: ${props => props.big ? '2em' : '1em'};
  }
`

const StyledTextArea = styled.div`
  position: relative;
  height: 100%;
  border: 1px solid #00000011;

  textarea {
    width: 100%; height: 100%;
    border: none;
    resize: none;
    outline: none;
  }

  textarea:focus {
    border-color: teal;
  }
`

// module.exports = {
//   TextArea: TextArea,
//   TextField: TextField
// };

function guidGenerator() {
    let S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import styled from 'styled-components';
import firebase from 'firebase';

import Main from './components/Main';
import Auth from './components/Auth';
import Header from './components/Header';

import firebaseConfig from './firebase_config.json';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

class EvoAdminDashboardComponent extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isLoggedIn: false,
      isAdmin: false,
      currentUser: {},
      stopLoader: false,
      redirectHash: null,
    };
    this.checkAuth = this.checkAuth.bind(this);
  }

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth() {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        firebase.database().ref('/systemSettings/admins')
        .child(user.uid)
        .once('value', function(snapshot) {
          if (snapshot.exists() && snapshot.val()) {
            // User is admin
            this.setState({
              isLoggedIn: true,
              isAdmin: true,
              currentUser: user,
              stopLoader: true,
            });
          }else {
            this.setState({
              currentUser: user,
              stopLoader: true,
              isLoggedIn: true,
              isAdmin: false
            });
          }
        }.bind(this));
        return true;
      } else {
        // User is signed out.
        this.setState({
          stopLoader: true,
          isAdmin: false,
          isLoggedIn: false,
          currentUser: {}
        });
        return false;
      }
      // ...
    }.bind(this));
  }

  render() {
    const {
      currentUser,
      isLoggedIn,
      isAdmin,
      stopLoader
    } = this.state;

    if (stopLoader) {
      return(
        <div>
          <Switch>
            <Route exact path="/auth" render={() => (
              isLoggedIn
              ? <Redirect to="/" />
              : <GuestWrapper>
                  <Auth />
                  <HeroBanner>
                    <HeroTitle>Evo<span>Admin</span></HeroTitle>
                  </HeroBanner>
                </GuestWrapper>
            )} />
            <Route path="/" render={() => (
              isLoggedIn
              ? <Wrapper>
                  <Header
                    className="header-comp"
                    currentUser={currentUser}
                  />
                  <Main
                    isLoggedIn={isLoggedIn}
                    isAdmin={isAdmin}
                    currentUser={currentUser}
                  />
                </Wrapper>
              : <Redirect to="/auth" />
            )} />
          </Switch>
        </div>
      );
    }else {
      return(
        <Loader />// TODO: animate and turn into a module
      )
    }
  }
};

/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const GuestWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #f5f5f5;
`

const HeroBanner = styled.div`
  width: 100%; height: 100%;
  background: #0c2733;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeroTitle = styled.h1`
  color: white;
  font-size: 4em;
  font-weight: bold;

  span {
    color: teal;
  }
`

const Loader = styled.div`
  height: 100%; width: 100%;
  background: #0c2733;
  overflow: hidden;
`
const Wrapper = styled.div`
  width: 100vw; height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-rows: 75px 1fr;

.header-comp {
  grid-column: 1/3;
}
`
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

ReactDOM.render(
  <Router>
    <EvoAdminDashboardComponent />
  </Router>,
  document.getElementById('app')
);

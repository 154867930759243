import React from 'react';
import {Link, Redirect} from 'react-router-dom';

import styled from 'styled-components';
import firebase from 'firebase';

export default class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return(
      <NavWrapper>
        <Link to="/">
          <NavItem>
            <i className="fa fa-home">
              <span>
                Home
              </span>
            </i>
          </NavItem>
        </Link>
        <Link to="/users">
          <NavItem>
            <i className="fa fa-users">
              <span>
                Users
              </span>
            </i>
          </NavItem>
        </Link>
        <Link to="/discussions">
          <NavItem>
            <i className="fa fa-comment-alt">
              <span>
                Discussions
              </span>
            </i>
          </NavItem>
        </Link>
        <Link to="/notification/text">
          <NavItem>
            <i className="fa fa-bell">
              <span>
                Notification
              </span>
            </i>
          </NavItem>
        </Link>
      </NavWrapper>
    );
  }
};


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const NavWrapper = styled.nav`
  margin: 35px;
  margin-right: 0;
  padding: 1em 0;
  background: #0c2733;
  box-shadow: rgb(12, 39, 51) 0px 4px 35px -10px;
  max-height: calc(50% - 35px);
  height: 12em; /* TODO: fix height: auto */

  ${NavWrapper} a {
    color: white;
    text-decoration: none;
  }
`

const NavItem = styled.div`
  height: 2.5em;
  font-size: 1em;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: left;

  ${NavItem}:hover {
    background: #164458;
    cursor: pointer;
    ${'' /* border-right: 5px solid teal; */}
    /* TODO: notification comp doesn't like nav borders??? */
  }

  i {
    font-size: 1em;
    color: teal;
  }

  i span {
    padding-left: 10px;
    margin-right: 10px;
    color: white;
    font-family: 'Source Sans Pro';
  }

`

const NullDiv = styled.div`
  width: 0;
`

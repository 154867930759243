import styled from 'styled-components';

export const Card = styled.section`
  width: 100%; height: 100%;
  max-height: calc( 100vh - 75px - 70px );
  background: white;
  box-shadow: rgb(112, 112, 112) 0px 4px 70px -18px;
`;

export const CardHead = styled.div`
  width: 100%; height: 4em;
  border-bottom: 2px solid lightgrey;
  display: grid;
  grid-template-columns: 1fr 4em;
  align-items: center;
`;

export const CardTitle = styled.h1`
  margin: 0;
  padding-left: 15px;
`;

export const CardExtra = styled.div`
  font-size: 1.5em;
  text-align: center;

  i {
    color: #aaa;
  }

  i:hover {
    color: teal;
    cursor: pointer;
  }

`

export const CardContent = styled.div`
  width: 100%;
  height: calc( 100% - 4em );
  display: grid;
`;

// module.exports = {
//   Card: Card,
//   CardHead: CardHead,
//   CardTitle: CardTitle,
//   CardExtra: CardExtra,
//   CardContent: CardContent
// };

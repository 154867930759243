import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Switch, Redirect} from 'react-router-dom';

import styled from 'styled-components';
import firebase from 'firebase';

import Users from './Users';
import Discussions from './Discussions/index.jsx';
import Home from './Home/index.jsx';
import Nav from './Nav';
import Notification from './Notification';
import SingleUser from './Users/SingleUser';
import SingleDisc from './Discussions/SingleDisc';


export default class Main extends React.Component{

  render() {

    let {
      currentUser,
      isAdmin 
    } = this.props;

    return (
      <Wrapper>
        {isAdmin 
          ? <IsAdminWrapper>
              <Nav /> 
              <MainWrapper>
                  <Switch>
                    <PropsRoute
                      exact path='/'
                      component={Home}
                      currentUser={currentUser}
                    />
                    <Route exact path='/users' component={Users} />
                    <Route path='/users/:uid' component={SingleUser} />
                    <Route exact path='/discussions' component={Discussions} />
                    <Route path='/discussions/:uid' component={SingleDisc} />
                    <PropsRoute
                      path='/notification/:type/:uid?'
                      component={Notification}
                      currentUser={currentUser}
                    />
                    <Redirect from='/notification' to='/notification/text' />
                  </Switch>
              </MainWrapper>
            </IsAdminWrapper>
          : <NonAdminWrapper className="non-admin">
              <div>
                You are not authorized to access this app.<br />
                For more information contact admin@getevoapp.com
              </div>
            </NonAdminWrapper>
        }
      </Wrapper>
    );
  }
};

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );
}


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const Wrapper = styled.div`
  width: 100%; height: 100%;
  z-index: 1;
  background: #eeeeee;

  .header-comp {
    grid-column: 1/3;
  }

  .non-admin {
    grid-column: 1/3;
  }
`

const MainWrapper = styled.div`
  margin: 0;
  display: grid;
  grid-row-gap: 5px;
`;

const IsAdminWrapper = styled.div`
  width: 100%; height: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column: 1/3;
`

const NonAdminWrapper = styled.div`
  height: calc(100% - 75px);
  display: flex;
  justify-self: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 2em;
`
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

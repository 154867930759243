import React from 'react';

import styled from 'styled-components';
import firebase from 'firebase';

import {loadRecordsReversed, loadNewRecordsPageReversed,  loadSearchedRecords, convertUnixTimestamp, debounce} from '../../helpers/Utils';

export default class Disc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discussions: [],
      selectedDisc: ''
    };

    this.loadRecordsReversed = loadRecordsReversed.bind(this);
    this.loadNewRecordsPageReversed = loadNewRecordsPageReversed.bind(this);
    this.loadSearchedRecords = loadSearchedRecords.bind(this);
    this.handleScrollLoad = this.handleScrollLoad.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.loadRecordsReversed('/discussions', 'discussions', 'orderIndex');
  }

  handleScrollLoad(){
    const container = document.getElementById('disc-list');
    const viewportHeight = container.clientHeight;
    const pageHeight = container.scrollHeight;
    const scrollPos = container.scrollTop;
    if (scrollPos == pageHeight - viewportHeight) {
      const {
        discussions
      } = this.state;

      let startAtValue = discussions[discussions.length-1].orderIndex;
      let startAtUid = discussions[discussions.length-1].uid;
      this.loadNewRecordsPageReversed('/discussions',
                              'discussions',
                              'orderIndex',
                              startAtValue,
                              startAtUid,
                              10,
                              discussions);
    }
  }

  handleSearch(event) {
    const searchKeyword = event.target.value;

    let getSearchResults = function() {
      document.getElementById('disc-list').scrollTop = 0;

      this.setState({
        searchKeyword: searchKeyword,
      });

      this.loadSearchedRecords('/discussions', 'discussions', 'uid', searchKeyword);
    }.bind(this);

    let throttledGetSearchResults = debounce(getSearchResults, 500);
    throttledGetSearchResults();
  };


  gatherDiscProxy() {
    let uid = this.state.selectedDisc;
    this.props.onSelect(uid);
  }

  async handleSelect(event) {
    event.preventDefault();
    const selectedElement = event.currentTarget;
    const uid = event.currentTarget.id;
    const selectedDiscUid = this.state.selectedDisc;


    if (selectedDiscUid != '') {
      if (uid == selectedDiscUid) {
        selectedElement.classList.remove('selected');
        await this.setState({
          selectedDisc: ''
        })
      }else {
        let prevSelectedDisc = document.getElementById(selectedDiscUid);
        prevSelectedDisc.classList.remove('selected');
        selectedElement.classList.add('selected');
        await this.setState({
          selectedDisc: uid
        })
      }
    }else {
      selectedElement.classList.add('selected');
      await this.setState({
        selectedDisc: uid
      })
    }

    this.gatherDiscProxy();
  }

  render() {
    const discussions = this.state.discussions;

    return (
      <Wrapper>
        <NotificationContent
          id="disc-list"
          onScroll={this.handleScrollLoad}
        >
          {discussions.map((disc, index) =>
            <DiscItem
              key={disc.uid}
              id={disc.uid}
              onClick={this.handleSelect}
            >
              <DiscHead>
                 <DiscAuthorPic>
                   <img src={disc.authorAvatarLink} alt="" />
                 </DiscAuthorPic>
                 <DiscAuthorName>
                   <span>{disc.authorName}</span>
                 </DiscAuthorName>
                 <DiscCreationDate>
                   {convertUnixTimestamp(disc.creationDate)}
                 </DiscCreationDate>
               </DiscHead>
               <DiscContent>
                 {disc.message}
               </DiscContent>
            </DiscItem>
          )}
        </NotificationContent>
        <DiscussionSearch>
          <i className="fa fa-search"></i>
          <input type="text" onChange={this.handleSearch} placeholder="Search discussions by uid..."/>
        </DiscussionSearch>
      </Wrapper>
    );
  }
}


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */

const Wrapper = styled.div`
  width: 100%; height: 100%;
  display: grid;
  grid-template-rows: 1fr 2em;
  height: calc( 100vh - 7em - 140px - 75px - 140px ) ;
`

const NotificationContent = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: 100%;
  grid-template-rows: auto;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
`

const DiscItem = styled.div`
  border: 1px solid #eee;
  min-height: 6em;
  display: grid;
  grid-template-columns: 1fr 4fr;
  transition: .2s linear;

  &:hover,
  &.selected {
    border-color: #888;
    cursor: pointer;
    background: #eee;
  }
`

const DiscHead = styled.div`
`

const DiscAuthorPic = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 5px;
  margin-bottom: 0;

  img {
   width: 40px; height: 40px;
   /* margin: auto; */
   background-image: url(https://firebasestorage.googleapis.com/v0/b/tefter-be7a6.appspot.com/o/app%2FanonymousLogo.jpg?alt=media&token=0e6f0b2c-ac8c-4907-b1a9-3c3fb69b4288);
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
   border-radius: 50%;
   object-fit: cover;
  }

`

const DiscAuthorName = styled.div`
  font-size: .85em;
`

const DiscCreationDate = styled.div`
  font-size: .8em;
  color: #666;
`

const DiscContent = styled.div`
  padding: .5em;
  line-height: 1em;
  height: 5.5em; ${'' /* 5 lines + .5em padding */}
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`

const DiscussionSearch = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  i {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 5px;
    width: 2em;
    font-size: .8em;
    color: teal;
  }

  input {
    height: 2em;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 3em;
    border: 1px solid #ddd;
  }

  select {
    height: 2.5rem;
    margin-right: 5px;
    border: 1px solid #dddddd;
  }
`
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

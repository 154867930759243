import React from 'react';

import styled from 'styled-components';
import firebase from 'firebase';

import {loadRecords, loadNewRecordsPage, loadSearchedRecords, throttle, debounce} from '../../helpers/Utils';

export default class ScopeUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      recipients: ['empty'],
      namedRecipients: [{firstName: null, uid: 'empty'}],
      searchKeyword: ''
    };

    this.loadRecords = loadRecords.bind(this);
    this.loadNewRecordsPage = loadNewRecordsPage.bind(this);
    this.loadSearchedRecords = loadSearchedRecords.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleScrollLoad = this.handleScrollLoad.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  componentDidMount() {
    const orderBy = this.props.params.orderBy;
    this.loadRecords('/users', 'users', orderBy);
  }

  async componentWillUpdate() {
    const reset = this.props.reset;
    if (reset) {
      await this.setState({
        users: []
      });
      const orderBy = this.props.params.orderBy;
      this.loadRecords('/users', 'users', orderBy);
    }
  }

  handleScrollLoad(){
    const container = document.getElementById('user-list');
    const viewportHeight = container.clientHeight;
    const pageHeight = container.scrollHeight;
    const scrollPos = container.scrollTop;

    if (scrollPos == pageHeight - viewportHeight) {
      const {
        users
      } = this.state;

      const params = this.props.params;
      const startAtValue = users[users.length-1][params.orderBy];
      const startAtUid = users[users.length-1].uid;

      this.loadNewRecordsPage('/users',
                              'users',
                              params.orderBy,
                              startAtValue,
                              startAtUid,
                              params.usersPerLoad,
                              users);
    }
  };

  handleSearch(event) {
    const searchKeyword = event.target.value;

    let getSearchResults = function() {
      document.getElementById('user-list').scrollTop = 0;

      this.setState({
        searchKeyword: searchKeyword,
      });

      const orderBy = this.props.params.orderBy;
      this.loadSearchedRecords('/users', 'users', orderBy, searchKeyword);
    }.bind(this);

    let throttledGetSearchResults = debounce(getSearchResults, 500);
    throttledGetSearchResults();
  };

  handleToggleSelectAll() {
    let source = document.getElementById('select-all');
    let checkboxes = document.getElementsByName('user-chk');

    for(let i=0, n=checkboxes.length;i<n;i++) {
      checkboxes[i].checked = source.checked;
    }
  };

  handleSelect(event) {
    const checkbox = event.target;
    let checked = checkbox.checked;

    let {
      recipients,
      namedRecipients
    } = this.state;

    if (checkbox.id == 'select-all') {
      if (checkbox.checked) {
        recipients = ['all'];
        namedRecipients = [{firstName: null, uid: 'all'}];
      } else {
        recipients = ['empty'];
        namedRecipients = [{firstName: null, uid: 'empty'}];
      }
    } else {
      let buffer = {
        uid: checkbox.id,
        firstName: checkbox.placeholder
      };

      if (checked) { // add to selection
        if (recipients[0] == 'empty') {
          recipients[0] = 'select';
          namedRecipients[0].uid = 'select'
          recipients.push(checkbox.id);
          namedRecipients.push(buffer);
        } else if (recipients[0] == 'all-except') {
          let index = recipients.indexOf(checkbox.id);
          recipients.splice(index, 1);
          if (recipients.length <= 1) {
            recipients[0] = 'all';
            namedRecipients[0].uid = 'all'
            document.getElementById('select-all').checked = true;
          }

          namedRecipients.forEach((recipient, rindex) => {
            if (recipient.uid == checkbox.id) {
              namedRecipients.splice(rindex, 1);
            }
          });
        } else {
          recipients.push(checkbox.id);
          namedRecipients.push(buffer);
        }
      } else { //remove from selection
        if (recipients[0] == 'all') {
          document.getElementById('select-all').checked = false;
          recipients.push(checkbox.id);
          namedRecipients.push(buffer);
          recipients[0] = 'all-except'
          namedRecipients[0].uid = 'all-except'
        } else if (recipients[0] == 'all-except') {
          recipients.push(checkbox.id);
          namedRecipients.push(buffer);
        }else {
          let index = recipients.indexOf(checkbox.id);
          recipients.splice(index, 1);
          namedRecipients.forEach((recipient, rindex) => {
            if (recipient.uid == checkbox.id) {
              namedRecipients.splice(rindex, 1);
            }
          });

          if (recipients.length <= 1) {
            recipients[0] = 'empty';
            namedRecipients[0].uid = 'empty'
          }

        }
      }
    };

    this.setState({
      recipients: recipients,
      namedRecipients: namedRecipients
    })
    this.props.recipientProxy(recipients, namedRecipients);
    this.props.gatherData(recipients);
  }

  isSelected(uid) {
    let recipients = this.state.recipients.slice();
    let {recipientsFromParent} = this.props;

    if (recipientsFromParent.length > 1) {
      recipients[0] = recipientsFromParent[0].uid;
      recipientsFromParent.forEach((parentRecipient) => {
        let skip = recipients.find((recipient) => {
          return recipient == parentRecipient.uid;
        })

        if (!skip) {
          recipients.push(parentRecipient.uid);
        }
      })
    }

    const target = document.getElementById(uid);

    if (recipients) {
      if (recipients[0] == 'all') {
        return true;
      }else if (recipients[0] == 'all-except') {
        if (recipients.indexOf(uid) == -1) {
          return true;
        }
      }else if (recipients[0] == 'select') {
        if (recipients.indexOf(uid) != -1) {
          return true;
        }
      }
    }

    return false;
  };

  render() {
    let users = this.state.users;
    if (!users) {
      users = [];
    }
    return (
      <Wrapper>
        <UserList
          id="user-list"
          onScroll={this.handleScrollLoad}
        >
          <UserItem>
            <input
              id="select-all"
              type="checkbox"
              onClick={this.handleToggleSelectAll}
              onChange={this.handleSelect}
            />
            <label htmlFor="select-all">Select All</label>
          </UserItem>
          {users.map((user, index) =>
            <UserItem key={user.uid}>
              <input
                name="user-chk"
                id={user.uid}
                placeholder={user.firstName}
                type="checkbox"
                onChange={this.handleSelect}
                checked={
                  this.isSelected(user.uid)
                }
              />
              <label htmlFor={user.uid}>{user.firstName}</label>
              <span>{user.uid}</span>
            </UserItem>
          )}
        </UserList>
        <UserSearch>
          <i className="fa fa-search"></i>
          <input type="text" onChange={this.handleSearch} placeholder="Search users..."/>
        </UserSearch>
      </Wrapper>
    );
  }
}


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 1fr 35px;
  grid-row-gap: 10px;

`

const UserList = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 7em - 250px );
  display: grid;
  grid-auto-rows: 3em;
  grid-row-gap: 5px;
  border: 1px solid #ddd;
`

const UserItem = styled.div`
  height: 3em;
  display: grid;
  grid-template-columns: 3em 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;

  input {
    grid-column: 1/2;
    grid-row: 1/3;
    align-self: center;
    margin: auto;
    cursor: pointer;
    width: 3em; height: 3em;

  }

  input:checked ~ * {
    color: teal;
  }

  input:checked ~ *:hover {
    color: white;
  }

  label {
    grid-column: 2/3;
    grid-row: auto;
  }

  span {
    grid-row: auto;
    grid-column: 2/3;
    font-size: .7em;
  }

  ${UserItem}:first-child label {
    grid-row: 1/3;
    align-self: center;
  }

  ${UserItem}:hover {
    background: #04b5b5;
    color: white !important;
  }
`

const UserSearch = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  i {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 2em;
    font-size: .8em;
    color: teal;
  }

  input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 2em;
    border: 1px solid #ddd;
    min-height: 2em;
  }
`

import React from 'react';
import {Link} from 'react-router-dom';

import styled from 'styled-components';
import firebase from 'firebase';

import {Card, CardHead, CardTitle, CardExtra, CardContent} from '../helpers/Card';
import {loadRecords, loadNewRecordsPage, loadSearchedRecords, throttle, debounce} from '../helpers/Utils';

export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      searchKeyword: '',
      orderBy: 'firstName',
      stopLoader: true,
      lastKey: '',
      lastValue: '',
      usersPerLoad: 25,
    };

    this.handleScrollLoad = this.handleScrollLoad.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.setOrderBy = this.setOrderBy.bind(this);
    this.loadRecords = loadRecords.bind(this);
    this.loadNewRecordsPage = loadNewRecordsPage.bind(this);
    this.loadSearchedRecords = loadSearchedRecords.bind(this);
  }

  componentDidMount() {
    const orderBy = this.state.orderBy;
    this.loadRecords('/users', 'users', orderBy);
  }

  handleScrollLoad(){
    const container = document.getElementById('user-list');
    const viewportHeight = container.clientHeight;
    const pageHeight = container.scrollHeight;
    const scrollPos = container.scrollTop;


    if (scrollPos == pageHeight - viewportHeight) {
      const keys = [];
      const {
        users,
        usersPerLoad,
        orderBy,
        lastKey,
        lastValue
      } = this.state;

      const startAtValue = users[users.length-1].firstName;
      const startAtUid = users[users.length-1].uid;

      this.loadNewRecordsPage('/users',
                              'users',
                              orderBy,
                              startAtValue,
                              startAtUid,
                              usersPerLoad,
                              users);
    }
  }

  handleSearch(event) {
    const searchKeyword = event.target.value;

    let getSearchResults = function() {
      document.getElementById('user-list').scrollTop = 0;

      this.setState({
        searchKeyword: searchKeyword,
      });

      const orderBy = this.state.orderBy;
      this.loadSearchedRecords('/users', 'users', orderBy, searchKeyword);
    }.bind(this);

    let throttledGetSearchResults = throttle(getSearchResults, 500);
    throttledGetSearchResults();
  };

  async setOrderBy(event) {
    const orderBy = event.target.value;
    await this.setState({
      orderBy: orderBy
    });
  }

  render() {
    const {orderBy} = this.state;
    if (this.state.stopLoader) {
      return (
        <MainWrapper>
          <Card>
              <CardHead>
                <CardTitle>Users</CardTitle>
                <CardExtra className="fa fa-cogs"></CardExtra>
              </CardHead>
              <CardContent>
                <ListWrapper
                  id="user-list"
                  onScroll={this.handleScrollLoad}
                >
                  <UsersHeader>
                    <span>Display Name</span>
                    <span>Email</span>
                    <span>UID</span>
                  </UsersHeader>
                  {this.state.users.map((user, i) =>
                    <Link to={'/users/' + user.uid} key={user.uid+i}>
                      <User>
                        <UserName>
                          {user.firstName
                            ? user.firstName
                            : 'Undefined'}
                        </UserName>
                        <UserEmail>{user.email}</UserEmail>
                        <UserId>{user.uid}</UserId>
                      </User>
                    </Link>
                  )}
                </ListWrapper>
                <UserSearch>
                  <i className="fa fa-search"></i>
                  <input type="text" onChange={this.handleSearch} placeholder={'Search users by '+orderBy+'...'}/>
                  <select onChange={this.setOrderBy}>
                    <option value="firstName">firstName</option>
                    <option value="uid">uid</option>
                    <option value="email">email</option>
                  </select>
                </UserSearch>
            </CardContent>
          </Card>
        </MainWrapper>
      )
    }else {
      return(
        <Loader />// TODO: animate and turn into a module
      )
    }
  }
}


/* ~~~~~~~ STYLED COMPONENTS ~~~~~~~ */
const Loader = styled.div`
  height: 100%; width: 100%;
  background: #0c2733;
  overflow: hidden;
`;

const MainWrapper = styled.div`
  max-height: calc( 100vh - 75px - 70px );
  margin: 35px;
  box-shadow: rgb(112, 112, 112) 0px 4px 70px -18px;

  display: grid;

  ${CardContent} {
    height: calc( 100vh - 4em - 75px - 70px );
    display: grid;
    grid-template-rows: 1fr 3em;
  }

`;

const ListWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;

  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  a {
    color: #555;
    text-decoration: none;
  }

  a:nth-child(2n) {
    background: #006d841a;
  }
`

const UsersHeader = styled.header`
  padding: 0 10px;
  display: grid;
  grid-template-columns: 40% 1fr 25%;
  grid-column-gap: 10px;
  position: sticky;
  top: 0;
  background: #98c6c6;

  span:last-child {
    text-align: center;
  }
`;

const User = styled.div`
  width: 100%; height: 2.5em;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 40% 1fr 10% auto;
  grid-column-gap: 10px;
  transition: background .1s linear;

  ${User}:hover {
    background: #98c6c6;
  }
`;

const UserPic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  float: left;

  img {
    width: 50px; height: 50px;
    margin: auto;
    border-radius: 100%;
  }
`;

const UserName = styled.span`
  display: inline;
  font-size: 1.5em;
  text-align: left;
  align-self: center;
`;

const UserEmail = styled.span`
  display: inline;
  font-size: 1em;
  text-align: left;
  align-self: center;
`;

const UserId = styled.span`
  display: inline;
  text-align: center;
  align-self: center;
  grid-column: 4/5;
`;

const UserSearch = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  i {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 5px;
    width: 2em;
    font-size: .8em;
    color: teal;
  }

  input {
    height: 3em;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 2em;
    border: 1px solid #ddd;
  }

  select {
    height: 2.5rem;
    margin-right: 5px;
    border: 1px solid #dddddd;
  }
`
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
